@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply w-[200px] px-4 py-2 border-2 border-orange-600 text-orange-600 rounded hover:bg-orange-600 hover:text-white text-base tracking-wide font-semibold;
  }

  .container-no-bg-horizontal {
    @apply px-2 md:px-10 py-6 w-full max-h-none flex flex-wrap justify-around items-center rounded-xl overflow-x-hidden;
  }

  .container-no-bg-vertical {
    @apply px-2 md:px-10 py-6 mb-16 w-full max-h-none flex flex-col justify-center items-center rounded-xl;
  }
}

* {
  font-family: monospace;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem;
}

html {
  scroll-behavior: smooth;
}